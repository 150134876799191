import { rootApi } from "../../../redux/rootApi";
import { setToken } from "../../../helpers/localStorage";

const loginApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (payload) => ({
        url: "/users/login",
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => {
        setToken(response.user?.token || "");
        return response;
      },
    }),
    autologin: build.query({
      query: () => ({
        url: "/users/current",
        method: "GET",
      }),
      transformResponse: (response) => {
        setToken(response.user?.token || "");
        return response;
      },
    }),
  }),
});

export const { useLoginMutation, useLazyAutologinQuery } = loginApi;

export default loginApi;
