import React, { Suspense, useEffect } from "react";
import Routes from "./router/Routes";
import { setToken } from "./helpers/localStorage";
import Loader from "./views/common/Loader";
import { toast } from "react-toastify";
import { useLazyAutologinQuery } from "./views/login/redux/api";

export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const SHOW_MESSAGE = "SHOW_MESSAGE";

const nonRedirectPaths = ["/login"];

const App = () => {
  const [autologin, { isLoading, isSuccess }] = useLazyAutologinQuery();

  const handleTokenExpired = () => {
    setToken("");
    if (nonRedirectPaths.includes(window.location.pathname)) return;
    window.location = "/login";
  };

  const handleShowMessage = ({ detail = {} }) => {
    const { title, variant = "success" } = detail;
    toast[variant](title);
  };

  useEffect(() => {
    autologin();
    window.addEventListener(TOKEN_EXPIRED, handleTokenExpired);
    window.addEventListener(SHOW_MESSAGE, handleShowMessage);

    return () => {
      window.removeEventListener(TOKEN_EXPIRED, handleTokenExpired);
      window.removeEventListener(SHOW_MESSAGE, handleShowMessage);
    };
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          <Routes isAuth={isSuccess} />
        </Suspense>
      )}
    </div>
  );
};

export default App;
