import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getToken } from "../helpers/localStorage";

const HOST =
  process.env.NODE_ENV === "production"
    ? "https://cafe.space.if.ua/api/"
    : "http://localhost:5001/api/";

export const IMAGE_HOST =
  process.env.NODE_ENV === "production"
    ? "https://cafe.space.if.ua/images/"
    : "http://localhost:5001/images/";

const baseQuery = fetchBaseQuery({
  baseUrl: HOST,
  prepareHeaders: (headers) => {
    const token = getToken();

    if (token) {
      headers.set("authorization", `Token ${token}`);
    }

    return headers;
  },
});

const customBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    window.dispatchEvent(new CustomEvent("TOKEN_EXPIRED"));
  }

  return result;
};

export default customBaseQuery;
