import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "./baseQuery";

export const rootApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    "ClientsList",
    "SkuOwner",
    "DiscountsList",
    "MarkupsList",
    "ProductGroupsList",
    "ClientGroupsList",
  ],
});
