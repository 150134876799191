import React, { useState } from "react";
import { Avatar, Layout, Menu } from "antd";
import menuList from "./menuList";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./styles.scss";
import dayjs from "dayjs";
import { UserOutlined } from "@ant-design/icons";
import { TOKEN_EXPIRED } from "../../App";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const MainLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const { isAuth } = props;

  const navigate = useNavigate();

  if (!isAuth) {
    navigate("/login");
  }

  const profileMenuItems = [
    {
      icon: (
        <Avatar
          size={32}
          className="profile-ant-avatar"
          icon={<UserOutlined height={32} width={32} />}
        />
      ),
      key: "profile",
      children: [
        {
          label: "Выйти",
          key: "logout",
          onClick: () => window.dispatchEvent(new CustomEvent(TOKEN_EXPIRED)),
        },
      ],
    },
  ];

  const menu = menuList.map(({ icon: Icon, ...item }) => {
    if (item.subItems) {
      return (
        <SubMenu key={item.title} icon={<Icon />} title={item.title}>
          {item.subItems.map(({ icon: SubIcon, ...el }) => (
            <Menu.Item key={el.path}>
              <NavLink to={el.path}>{el.title}</NavLink>
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={item.path} icon={<Icon />}>
        <NavLink to={item.path}>{item.title}</NavLink>
      </Menu.Item>
    );
  });

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          {menu}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Menu
            mode="horizontal"
            className="profile"
            items={profileMenuItems}
          />
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>WOL ©{dayjs().year()}</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
