import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Space,
} from "antd";
import "./styles.scss";
import logo from "../../images/logo.png";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "./redux/api";

const Login = () => {
  const [login, { isLoading, isSuccess }] = useLoginMutation();

  const navigate = useNavigate();

  const onFinish = (user) => {
    login({ user });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
    }
  }, [isSuccess]);

  return (
    <Layout className="login-layout">
      <Row justify="center">
        <Col xs={20} sm={18} md={12} lg={8} xl={6}>
          <Card>
            <Space direction="vertical" size={80} style={{ width: "100%" }}>
              <Row justify="center">
                <Avatar
                  size={64}
                  icon={<img src={logo} alt="logo" width="100%" />}
                />
              </Row>
              <Row justify="center">
                <Col>
                  <Form name="user" onFinish={onFinish}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Username"
                      />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        placeholder="Password"
                      />
                    </Form.Item>

                    <Form.Item className="text-center">
                      <Button
                        type="primary"
                        className="bg-blue-500"
                        htmlType="submit"
                        disabled={isLoading}
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Space>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
