import CoffeeOutlined from "@ant-design/icons/CoffeeOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";

export default [
  {
    title: "Кафе",
    icon: CoffeeOutlined,
    subItems: [
      {
        title: "Продажа",
        path: "/sell",
      },
      {
        title: "Закупка",
        path: "/purchase",
      },
    ],
  },
  {
    title: "Настройки",
    icon: SettingOutlined,
    path: "/settings",
    subItems: [
      {
        title: "Клиенты",
        path: "/clients",
      },
      {
        title: "Товары",
        path: "/products",
      },
      {
        title: "Группы Клиентов",
        path: "/client-groups",
      },
      {
        title: "Группы Товаров",
        path: "/product-groups",
      },
      {
        title: "Наценки",
        path: "/markups",
      },
      {
        title: "Скидки",
        path: "/discounts",
      },
    ],
  },
];
