import { lazy } from "react";

const Home = lazy(() => import("../views/home/Home"));
const Sells = lazy(() => import("../views/cafe/Sells"));
const Markups = lazy(() => import("../views/markups/Markups"));
const Discounts = lazy(() => import("../views/discounts/Discounts"));
const ClientGroups = lazy(() => import("../views/clientGroups/ClientGroups"));
const ProductGroups = lazy(() =>
  import("../views/productGroups/ProductGroups")
);
const Products = lazy(() => import("../views/products/Products"));
const Clients = lazy(() => import("../views/clients/Clients"));
const Purchases = lazy(() => import("../views/purchases/Purchases"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    component: Home,
    path: "/",
    exact: true,
  },
  {
    component: Sells,
    path: "/sell",
    exact: false,
  },
  {
    component: Purchases,
    path: "/purchase",
    exact: false,
  },
  {
    component: Markups,
    path: "/markups",
    exact: false,
  },
  {
    component: Discounts,
    path: "/discounts",
    exact: false,
  },
  {
    component: ClientGroups,
    path: "/client-groups",
    exact: false,
  },
  {
    component: ProductGroups,
    path: "/product-groups",
    exact: false,
  },
  {
    component: Products,
    path: "/products",
    exact: false,
  },
  {
    component: Clients,
    path: "/clients",
    exact: false,
  },
];
