import { Route, Routes } from "react-router-dom";
import Login from "../views/login/Login";
import MainLayout from "../views/layout/MainLayout";
import routeList from "./routeList";
import React from "react";

const AppRoutes = ({ isAuth }) => {
  return (
    <Routes>
      <Route path="/login" element={<Login isAuth={isAuth} />} />
      <Route element={<MainLayout isAuth={isAuth} />}>
        {routeList.map(({ path, component: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
